export const types = {
  email: 'email',
  name: 'name',
  number: 'number',
  password: 'password',
  search: 'search',
  tel: 'tel',
  text: 'text',
  url: 'url',
};

export * from './InputLabels/index';
export * from './SelectField/index';
export * from './Input/index';
export * from './Textarea/index';
export * from './Checkbox';
