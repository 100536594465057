import React from 'react';
import Section from '../generic/Section';
import SearchResults from './SearchResults';
import styled from 'styled-components';
import SearchField from './SearchField';
import { universalMargin, xsmallBody } from 'styles';
import { Row, Column } from 'components/generic/Grid';

type SearchProps = {
  target: string;
};

export default function Search({ target }: SearchProps) {
  return (
    <Section size="max" spacing="none" innerPadding>
      <Row>
        <Column>
          <SearchContainer>
            <h1>Search</h1>
            <SubHead>Quickly find products and workspaces</SubHead>
            <SearchField />
            <SearchResults target={target} />
          </SearchContainer>
        </Column>
      </Row>
    </Section>
  );
}

const SearchContainer = styled.div`
  padding: ${universalMargin * 4}px 0;

  h1 {
    padding-bottom: ${universalMargin * 8}px;
  }
`;

const SubHead = styled.div`
  ${xsmallBody}
  padding-bottom: ${universalMargin * 2}px;
`;

export const encodeSearch = (str: string) => {
  return `?${new URLSearchParams({ search: str }).toString()}`;
};

export const decodeSearch = (location: string) => {
  return new URLSearchParams(location).get('search') || undefined;
};
