import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Image from '../generic/Image';
import { decodeSearch } from '.';
import getRoute from '../../routes/getRoute';
import styled, { css } from 'styled-components';
import { useLocation } from '@reach/router';
import { Product } from 'context/Quote/typedefs';
import { Image as ImageType, WorkspaceWithLinkRequirements } from 'types';
import { grey10, mediaQueries, universalMargin, xsmallBody } from 'styles';

const resultTypes = {
  product: 'product',
  workspace: 'workspace',
};

type args = {
  allContentfulProduct: { products: Array<Product> };
  allContentfulWorkspace: { workspaces: Array<WorkspaceWithLinkRequirements> };
};

type SearchDictionaryEntry = {
  slug: string;
  type: string;
  heading: string;
  image: ImageType;
  searchable: string;
  url: string;
};

type SearchDictionary = Array<SearchDictionaryEntry>;

function getDictionary(everything: args, target: string): SearchDictionary {
  const products = everything.allContentfulProduct.products
    .filter(item => item.includeInBuild?.includes(target))
    .map(item => {
      return {
        slug: item.slug,
        type: resultTypes.product,
        heading: item.heading,
        image: item.images?.[0].image,
        searchable: [item.heading, item.description?.description].join(' ').toLowerCase(),
        url: getRoute(item),
      };
    });

  const workspaces = everything.allContentfulWorkspace.workspaces.map(item => {
    return {
      slug: item.slug,
      type: resultTypes.workspace,
      heading: item.heading,
      image: item.hotspotHero.image,
      searchable: [item.heading, item.products?.flatMap(product => [product.heading, product.description?.description])]
        .join(' ')
        .toLowerCase(),
      url: getRoute(item),
    };
  });
  return [...products, ...workspaces];
}

type SearchResultsProps = {
  target: string;
};

export default function SearchResults({ target }: SearchResultsProps) {
  const everything = useStaticQuery(searchQuery);

  const [dictionary] = useState(getDictionary(everything, target));
  const [results, setResults] = useState<SearchDictionary>([]);
  const [info, setInfo] = useState('');
  const [query, setQuery] = useState('');
  const location = useLocation();

  useEffect(() => {
    const search = decodeSearch(location.search) || '';
    setQuery(currentQuery => (currentQuery !== search ? search : currentQuery));
  }, [location.search, query]);

  useEffect(() => setResults(query.length >= 3 ? filterResults(query, dictionary) : []), [query, dictionary]);

  useEffect(() => {
    switch (true) {
      case query.length < 3:
        setInfo('Minimum 3 characters ...');
        break;
      case results.length > 0:
        setInfo(`Results: ${results.length}`);
        break;
      default:
        setInfo('');
        break;
    }
  }, [results, query, setInfo]);

  return (
    <div>
      <Info>{info}</Info>
      {results.length > 0 && (
        <List>
          {results.map((item, index) => {
            return <Item key={index} item={item} />;
          })}
        </List>
      )}
    </div>
  );
}

const Info = styled.div`
  ${xsmallBody};
  padding: ${universalMargin * 2}px 0;
`;

const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -${universalMargin * 2}px;
  margin-right: -${universalMargin * 2}px;
`;

type ItemProps = {
  item: SearchDictionaryEntry;
};

function Item({ item }: ItemProps) {
  return (
    <ItemContainer>
      <Link to={item.url}>
        <ImageContainer>
          <StyledImage
            className={item.type}
            image={item.image?.gatsbyImageData}
            alt={item.image?.description}
            objectFit={item.type === 'workspace' ? 'cover' : 'contain'}
          />
        </ImageContainer>
        <ItemHeading>{item.heading}</ItemHeading>
      </Link>
    </ItemContainer>
  );
}

const ItemContainer = styled.li`
  width: calc((100% / 2) - ${universalMargin * 4}px);
  margin: ${universalMargin * 2}px;

  ${mediaQueries.medium(css`
    width: calc((100% / 3) - ${universalMargin * 4}px);
  `)}
  ${mediaQueries.large(css`
    width: calc((100% / 4) - ${universalMargin * 4}px);
  `)}
  ${mediaQueries.extraLarge(css`
    width: calc((100% / 6) - ${universalMargin * 4}px);
  `)}
  a {
    text-decoration: none !important;
  }
`;

const ItemHeading = styled.div`
  padding: ${universalMargin * 2}px 0;
`;

const ImageContainer = styled.div`
  height: auto;
  position: relative;
  background-color: ${grey10};
  width: 100%;
  height: auto;
  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

const StyledImage = styled(Image)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  &.product {
    width: calc(100% - ${universalMargin * 4}px);
    margin: 0 ${universalMargin * 2}px;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  &.workspace {
    width: 100%;
    height: 100%;
  }
`;

const searchQuery = graphql`
  query {
    allContentfulProduct(filter: { availability: { ne: "Future" }, slug: { regex: "/^[^!].+/" } }) {
      products: nodes {
        __typename
        slug
        heading
        includeInBuild
        description {
          description
        }
        images {
          ...PwpGridImage
        }
      }
    }
    allContentfulWorkspace(
      filter: {
        slug: { regex: "/^[^!].+/" }
        workspace_sub_category: {
          elemMatch: {
            slug: { regex: "/^[^!].+/" }
            workspace_category: { elemMatch: { slug: { regex: "/^[^!].+/" } } }
          }
        }
      }
    ) {
      workspaces: nodes {
        __typename
        slug
        heading
        hotspotHero {
          image {
            ...GridImage
          }
        }
        products {
          heading
          description {
            description
          }
        }
        workspaceSubCategory: workspace_sub_category {
          __typename
          slug
          heading
          description {
            description
          }
          workspaceCategory: workspace_category {
            __typename
            slug
          }
        }
      }
    }
  }
`;

function filterResults(query: string, dictionary: SearchDictionary) {
  const queryArr = query.toLowerCase().trim().split(' ');
  let max = 0;
  const res = (dictionary as Array<SearchDictionaryEntry & { matchCount?: number }>).filter(item => {
    item.matchCount = 0;
    queryArr.forEach(queryStr => {
      if (item.matchCount === undefined) return null;
      const match = item.searchable.indexOf(queryStr) !== -1;
      if (match) {
        item.matchCount++;
        max = Math.max(max, item.matchCount);
      }
    });
    return item.matchCount > 0;
  });

  return res.filter(item => item.matchCount || 0 === max);
}
