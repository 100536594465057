import React, { ChangeEvent, useEffect, useState } from 'react';
import { decodeSearch, encodeSearch } from '.';
import { navigate, useLocation } from '@reach/router';
import SearchIcon from './assets/search.svg';
import styled from 'styled-components';
import { types } from '../generic/InputField';

export default function SearchField() {
  const location = useLocation();
  const [query, setQuery] = useState(decodeSearch(location.search));

  useEffect(() => {
    const search = decodeSearch(location.search);
    setQuery(currentQuery => (currentQuery !== search ? search : currentQuery));
  }, [location.search, query]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setQuery(value);
    navigate([location.origin, location.pathname, encodeSearch(value)].join(''), {
      replace: true,
    });
  };

  return (
    <Form>
      <Input
        id="search"
        name="search"
        placeholder="Minimum 3 characters ..."
        type={types.search}
        required
        value={query}
        onChange={handleInputChange}
      />
      <StyledSearchIcon />
    </Form>
  );
}

const Form = styled.form`
  position: relative;
`;

const Input = styled.input`
  &&& {
    padding-left: 36px;
    width: calc(100% - 52px);
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  top: 8px;
  left: 10px;
  width: 20px;
  height: 20px;
`;
